import React, { useEffect, useState } from 'react';
import { MdPlaylistAdd, MdRemoveCircleOutline } from 'react-icons/md';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import usePieChart from '../../../hooks/usePieChart';
import uuid from '../../../utils/uuid';

import {
  PieChartActivity,
  PieChartActivityCategory,
  PieChartActivityCategoryArray,
} from '../../../types/journalItems/PieChart';

import styles from './PieChartTable.module.css';

const SAMPLE_ACTIVITY: PieChartActivity = {
  id: uuid(),
  name: 'Example 1',
  category: 'dailyobligations',
  hours: 0,
};

const PieChartTable = ({ isCurrent }: { isCurrent: boolean }) => {
  const {
    pieChart,
    updatePieChartCurrentActivities,
    updatePieChartIdealActivities,
  } = usePieChart();

  const [rows, setRows] = isCurrent
    ? useState<PieChartActivity[]>(
        pieChart?.currentActivities ?? [SAMPLE_ACTIVITY],
      )
    : useState<PieChartActivity[]>(
        pieChart?.idealActivities ?? [SAMPLE_ACTIVITY],
      );

  useEffect(() => {
    if (isCurrent) {
      updatePieChartCurrentActivities(rows);
    } else {
      updatePieChartIdealActivities(rows);
    }
  }, [rows, isCurrent]);

  const handleAdd = () => {
    setRows([
      ...rows,
      {
        id: uuid(),
        name: '',
        category: 'dailyobligations',
        hours: 0,
      },
    ]);
  };

  const handleRemove = (i: number) => {
    const list = [...rows];
    list.splice(i, 1);
    setRows(list);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    i: number,
  ) => {
    const { name, value } = e.target;

    const list = [...rows];
    if (name === 'hours') {
      list[i] = { ...list[i], hours: Number(value) };
    } else if (name === 'name') {
      list[i] = { ...list[i], name: value };
    } else if (name === 'category') {
      list[i] = { ...list[i], category: value as PieChartActivityCategory };
    }

    setRows(list);
  };

  return (
    <div>
      <TableContainer className={styles.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="center">Category</TableCell>
              <TableCell>Hours</TableCell>
              <TableCell align="center"> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((row, i) => (
                <TableRow key={i}>
                  <TableCell component="th" scope="row">
                    <input
                      required
                      value={row.name}
                      name="name"
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <select
                      required
                      name="category"
                      value={row.category}
                      onChange={(e) => handleInputChange(e, i)}
                    >
                      {PieChartActivityCategoryArray.map((option) => (
                        <option value={option}>{option}</option>
                      ))}
                    </select>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <input
                      required
                      type="number"
                      min="1"
                      max="24"
                      value={row.hours.toString()}
                      name="hours"
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <MdRemoveCircleOutline
                      size="20"
                      onClick={() => handleRemove(i)}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <div className="mt-4">
          <div className="flex flex-row gap-4">
            <Button onClick={handleAdd}>
              <MdPlaylistAdd size="30" onClick={handleAdd} className="mr-4" />
              ADD ROWS
            </Button>
          </div>
        </div>
      </TableContainer>
    </div>
  );
};

export default PieChartTable;
