import React, { useEffect, useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { MdLightbulbOutline } from 'react-icons/md';
import { Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import PieChartActivityPie from '../PieChartActivityPie';
import Button from '../../../shared/Button';
import NavigationButton from '../../../shared/NavigationButton';
import usePieChart from '../../../../hooks/usePieChart';
import ModalContext from '../../../../contexts/ModalContext';
import ROOT_URL from '../../../../constants/url';

import styles from './PieChartReflection.module.css';
import Reflections from '../../Reflections';

const PieChartReflection = ({
  setSlide,
}: {
  setSlide: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { pieChart, updatePieChartReflection, submitPieChart } = usePieChart();
  const { t } = useTranslation();
  const { emitter, events } = useContext(ModalContext);

  const [reflection, setReflection] = useState(pieChart?.reflection ?? '');
  const pageRef = useRef<HTMLDivElement>(null);

  const handleSubmit = () => {
    submitPieChart();
    const link = getSharableUrl();
    const message = t(`modals.share.piechartMessage`);
    emitter.emit(events.PIECHART_MODAL, {
      node: pageRef.current,
      link,
      message,
    });
  };

  const handleBack = () => {
    setSlide(3);
  };

  const getSharableUrl = () => {
    const pieChartId = pieChart?.id;
    return `${ROOT_URL}/app/journal/activity/piechart/${pieChartId}`;
  };

  useEffect(() => {
    updatePieChartReflection(reflection);
  }, [reflection]);

  return (
    <div ref={pageRef} style={{ backgroundColor: 'white' }}>
      <Grid container className={styles.container}>
        <Grid item md={6} className={styles.pieChart}>
          <h3>{t('activity.piechart.reflection.current')}</h3>
          <PieChartActivityPie isCurrent />
        </Grid>
        <Grid item md={6} className={styles.pieChart}>
          <h3>{t('activity.piechart.reflection.ideal')}</h3>
          <PieChartActivityPie isCurrent={false} />
        </Grid>
        <Grid item md={12}>
          <Alert
            severity="info"
            variant="standard"
            icon={<MdLightbulbOutline />}
            className={styles.alert}
          >
            <h2>
              <b>{t('activity.piechart.reflection.notes')}</b>
            </h2>
            <p>{t('activity.piechart.reflection.notes1')}</p>
            <p>{t('activity.piechart.reflection.notes2')}</p>
            <p>{t('activity.piechart.reflection.notes3')}</p>
          </Alert>
        </Grid>
        <Grid item md={12}>
          <Reflections
            id="pie-chart-reflection"
            prompt={
              <>
                <h2>{t('activity.piechart.reflection.reflections')}</h2>
                <p>{t('activity.piechart.reflection.reflections1')}</p>
                <p>{t('activity.piechart.reflection.reflections2')}</p>
              </>
            }
            reflection={reflection}
            onChange={(e) => setReflection(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <NavigationButton onClick={handleBack} alignLeft />
        </Grid>
        <Grid item xs={6} className={styles.submitContainer}>
          <Button onClick={handleSubmit}>
            {t('activity.piechart.reflection.finish')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default PieChartReflection;
