import { JournalItem, JournalItemEnum } from '../JournalItem';

export interface PieChart extends JournalItem {
  title: 'piechart';
  type: JournalItemEnum.activity;
  currentActivities: Array<PieChartActivity>;
  idealActivities: Array<PieChartActivity>;
  reflection: string;
}

export type PieChartActivity = {
  id: number;
  name: string;
  category: PieChartActivityCategory;
  hours: number;
};

export enum PieChartActivityCategoryEnum {
  timewasters = 'Time Wasters',
  relationships = 'Relationships',
  work = 'Work',
  dailyobligations = 'Daily Obligations',
}

export type PieChartActivityCategory = keyof typeof PieChartActivityCategoryEnum;

export const PieChartActivityCategoryArray: PieChartActivityCategory[] = [
  'timewasters',
  'relationships',
  'work',
  'dailyobligations',
];
