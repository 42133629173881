import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Container from '@material-ui/core/Container';

import LoadingScreen from '../../../../components/router/LoadingScreen';
import TopNavbar from '../../../../components/shared/TopNavbar';
import PieChartLanding from '../../../../components/journal/piechart/slide/PieChartLanding';
import PieChartUserInput from '../../../../components/journal/piechart/slide/PieChartUserInput';
import PieChartReflection from '../../../../components/journal/piechart/slide/PieChartReflection';

import usePieChart from '../../../../hooks/usePieChart';
import uuid from '../../../../utils/uuid';

import styles from './piechart.module.css';

const PieChart = ({ id }: { id: string }) => {
  const { t } = useTranslation();
  const {
    loadPieChart,
    createPieChart,
    resetPieChart,
    pieChart,
    isUpdating,
  } = usePieChart();
  const [loading, setLoading] = useState(true);
  const [slide, setSlide] = useState(0);
  const [activityId, setActivityId] = useState(id);

  useEffect(() => {
    resetPieChart();
    if (activityId == null) {
      const newId = uuid();
      createPieChart(newId);
      setActivityId(newId);
    } else {
      loadPieChart(activityId);
    }
    setLoading(false);
  }, [activityId]);

  useEffect(() => {
    if (slide !== 0) return;
    if (loading || isUpdating) return;
    if (pieChart == null && id != null) {
      navigate(-1);
      toast.error(t('activity.piechart.toasts.doesNotExist'));
      return;
    }
    setSlide(1);
  }, [pieChart, loading, isUpdating, setSlide]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Helmet>
        <title>Pie Chart of Time</title>
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Pie Chart of Time" />
        <meta
          property="og:image"
          content="https://careersocius-portal.web.app/static/4525c5a596897c4eda7fffd1cb4c431c/9e6d9/piechart.png"
        />
      </Helmet>
      <TopNavbar />
      <Container className={styles.contentContainer}>
        {slide === 1 && <PieChartLanding setSlide={setSlide} />}
        {slide === 2 && (
          <PieChartUserInput
            isCurrent
            handleNext={() => setSlide(3)}
            handleBack={() => setSlide(1)}
          />
        )}
        {slide === 3 && (
          <PieChartUserInput
            isCurrent={false}
            handleNext={() => setSlide(4)}
            handleBack={() => setSlide(2)}
          />
        )}
        {slide === 4 && <PieChartReflection setSlide={setSlide} />}
      </Container>
    </>
  );
};

export default PieChart;
