import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import styles from './PieChartLanding.module.css';
import NavigationButton from '../../../shared/NavigationButton';

const PieChartLanding = ({
  setSlide,
}: {
  setSlide: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { t } = useTranslation();
  const previews = useStaticQuery(graphql`
    query {
      main: file(relativePath: { eq: "activities/piechart/main.png" }) {
        childImageSharp {
          fluid(maxHeight: 200) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      workstudy: file(
        relativePath: { eq: "activities/piechart/workstudy.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 75) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      relationships: file(
        relativePath: { eq: "activities/piechart/relationships.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 75) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      dailyobligations: file(
        relativePath: { eq: "activities/piechart/dailyobligations.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 75) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      timewasters: file(
        relativePath: { eq: "activities/piechart/timewasters.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 75) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  const handleNext = () => {
    setSlide(2);
  };

  return (
    <div className={styles.container}>
      <Grid container className="flex justify-center">
        <Grid
          item
          md={6}
          lg={5}
          className="h-1/2 text-2xl text-center align-center"
        >
          <div>
            <h2>{t('activity.piechart.title')}</h2>
            <h4>{t('activity.piechart.header1')}</h4>
            <h4>{t('activity.piechart.header2')}</h4>
            <h5>{t('activity.piechart.header3')}</h5>
          </div>
        </Grid>
        <Grid item md={3} lg={2}>
          <GatsbyImage
            loading="eager"
            className="h-full w-full mx-auto align-center"
            fluid={previews.main.childImageSharp.fluid}
          />
        </Grid>
      </Grid>
      <Grid container className="flex flex-row justify-center mt-16">
        <Grid item md={6} lg={2}>
          <GatsbyImage
            loading="eager"
            className="h-1/2 w-1/2 mx-auto"
            fluid={previews.workstudy.childImageSharp.fluid}
          />
          <div>
            <h5>{t('activity.piechart.workStudy.title')}</h5>
            <p>{t('activity.piechart.workStudy.description')}</p>
          </div>
        </Grid>

        <Grid item md={6} lg={2}>
          <GatsbyImage
            loading="eager"
            className="h-1/2 w-1/2 mx-auto"
            fluid={previews.relationships.childImageSharp.fluid}
          />
          <div>
            <h5>{t('activity.piechart.relationships.title')}</h5>
            <p>{t('activity.piechart.relationships.description')}</p>
          </div>
        </Grid>

        <Grid item md={6} lg={2}>
          <GatsbyImage
            loading="eager"
            className="h-1/2 w-1/2 mx-auto"
            fluid={previews.timewasters.childImageSharp.fluid}
          />
          <div>
            <h5>{t('activity.piechart.timeWasters.title')}</h5>
            <p>{t('activity.piechart.timeWasters.description')}</p>
          </div>
        </Grid>

        <Grid item md={6} lg={2}>
          <GatsbyImage
            loading="eager"
            className="h-1/2 w-1/2 mx-auto"
            fluid={previews.dailyobligations.childImageSharp.fluid}
          />
          <div>
            <h5>{t('activity.piechart.dailyObligations.title')}</h5>
            <p>{t('activity.piechart.dailyObligations.description')}</p>
          </div>
        </Grid>
        <NavigationButton isNext onClick={handleNext} alignRight />
      </Grid>
    </div>
  );
};

export default PieChartLanding;
