import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import NavigationButton from '../../../shared/NavigationButton';
import PieChartTable from '../PieChartTable';
import PieChartActivityPie from '../PieChartActivityPie';

import usePieChart from '../../../../hooks/usePieChart';

import styles from './PieChartUserInput.module.css';

const PieChartUserInput = ({
  isCurrent,
  handleNext,
  handleBack,
}: {
  isCurrent: boolean;
  handleNext: () => void;
  handleBack: () => void;
}) => {
  const { pieChart } = usePieChart();
  const { t } = useTranslation();
  const [totalHours, setTotalHours] = useState(0);

  useEffect(() => {
    const rows = isCurrent
      ? pieChart?.currentActivities
      : pieChart?.idealActivities;

    if (rows == null) {
      setTotalHours(0);
      return;
    }

    const sum = rows.reduce((cummulativeSum, row) => {
      return cummulativeSum + row.hours;
    }, 0);

    setTotalHours(sum);
  }, [pieChart, isCurrent]);

  return (
    <Grid container className={styles.container}>
      <Grid item xs={12} className={styles.header}>
        <h2>
          {isCurrent
            ? t('activity.piechart.pieChartCurrent')
            : t('activity.piechart.pieChartIdeal')}
        </h2>
      </Grid>
      <Grid item md={6}>
        <div className={styles.alertContainer}>
          {totalHours !== 24 && (
            <Alert severity="info">
              <AlertTitle>
                <b>{t('activity.piechart.toasts.totalTime')}</b>
              </AlertTitle>
            </Alert>
          )}
        </div>
        <PieChartTable isCurrent={isCurrent} />
        <div className="mt-8">
          <p className="font-bold">
            {t(`activity.piechart.totalHours`)}
            {totalHours}
          </p>
        </div>
      </Grid>
      <Grid item md={6}>
        <PieChartActivityPie isCurrent={isCurrent} />
      </Grid>
      <Grid item xs={6}>
        <NavigationButton onClick={handleBack} alignLeft />
      </Grid>
      <Grid item xs={6}>
        <NavigationButton
          onClick={handleNext}
          isNext
          alignRight
          disabled={totalHours !== 24}
        />
      </Grid>
    </Grid>
  );
};

export default PieChartUserInput;
